import { Component, Show } from 'solid-js'
import { MagazinQuery } from '../../shared/generated/graphql'

interface ArticleProps {
	item: MagazinQuery['pages']['items'][number]
	i: number
}

const Article: Component<ArticleProps> = props => {
	return (
		<article class="magazin-teaser">
			<a href={props.item!.url}>
				<div class="background">
					<Show
						when={props.i == 0 || props.i == 3 || props.i == 8}
						fallback={
							<Show
								when={
									props.item.magazineAlternativeSmallTeaserImage &&
									props.item.magazineAlternativeSmallTeaserImage.url
								}
								fallback={
									<picture>
										<source
											data-srcset={`${props.item.magazineTeaserImage.cropVertical300} 300w, ${props.item.magazineTeaserImage.cropVertical500} 500w`}
											media="(min-width: 576px)"
										/>
										<source
											data-srcset={`${props.item.magazineTeaserImage.cropHorizontal300} 300w, ${props.item.magazineTeaserImage.cropHorizontal500} 500w`}
											media="(max-width: 576px)"
										/>
										<img
											data-srcset={props.item.magazineTeaserImage.url}
											class="lazyload vertical"
											data-sizes="auto"
											alt={props.item.magazineTeaserImage.alternative ?? ''}
										/>
									</picture>
								}>
								<picture>
									<img
										data-srcset={`${props.item.magazineAlternativeSmallTeaserImage!.image100} 100w, ${
											props.item.magazineAlternativeSmallTeaserImage!.image300
										} 300w, ${props.item.magazineAlternativeSmallTeaserImage!.image500} 500w`}
										data-src={props.item.magazineTeaserImage.url}
										class="lazyload alternative"
										data-sizes="auto"
										alt={props.item.magazineTeaserImage.alternative ?? ''}
									/>
								</picture>
							</Show>
						}>
						<picture>
							<img
								data-srcset={`${props.item.magazineTeaserImage.cropHorizontal100} 100w, ${props.item.magazineTeaserImage.cropHorizontal300} 300w, ${props.item.magazineTeaserImage.cropHorizontal500} 500w, ${props.item.magazineTeaserImage.cropHorizontal1000} 1000w`}
								data-src={props.item.magazineTeaserImage.url}
								class="lazyload horizontal"
								data-sizes="auto"
								alt={props.item.magazineTeaserImage.alternative ?? ''}
							/>
						</picture>
					</Show>
				</div>
				<div class="content">
					<h3 class="h4 title">{props.item.title}</h3>
				</div>
			</a>
		</article>
	)
}

export default Article
