import { Component, For } from 'solid-js'

interface Props {
	itemsPerPage: number
}

const ArticleSkeleton: Component<Props> = props => {
	return (
		<For each={[...Array(props.itemsPerPage)]}>
			{() => (
				<article class="magazin-teaser skeleton">
					<div class="animate-pulse" />
				</article>
			)}
		</For>
	)
}

export default ArticleSkeleton
